"use strict";

/**
 * Creates a clone of the Date object.
 * @return {Date}
 */
Date.prototype.clone = function() {
    return new Date(this.getTime());
}

/**
 * Copies the given date object to the current instance.
 * @param {Date} date
 * @return {Date}
 */
Date.prototype.copy = function(date) {
    this.setTime(date.getTime());

    return this;
}

/*
*
* Alterations
*
*/

/**
 * Adds the given seconds(s) to the date object.
 * @param {number} seconds
 * @return {Date}
 */
Date.prototype.addSeconds = function(seconds) {
    this.setSeconds(this.getSeconds() + seconds);

    return this;
}

/**
 * Subtracts the given seconds(s) from the date object.
 * @param {number} seconds
 * @return {Date}
 */
Date.prototype.subSeconds = function(seconds) {
    return this.addSeconds(-seconds);
}

/**
 * Adds the given minutes(s) to the date object.
 * @param {number} minutes
 * @return {Date}
 */
Date.prototype.addMinutes = function(minutes) {
    this.setMinutes(this.getMinutes() + minutes);

    return this;
}

/**
 * Subtracts the given minutes(s) from the date object.
 * @param {number} minutes
 * @return {Date}
 */
Date.prototype.subMinutes = function(minutes) {
    return this.addMinutes(-minutes);
}

/**
 * Adds the given hours(s) to the date object.
 * @param {number} hours
 * @return {Date}
 */
Date.prototype.addHours = function(hours) {
    this.setHours(this.getHours() + hours);

    return this;
}

/**
 * Subtracts the given hour(s) from the date object.
 * @param {number} hours
 * @return {Date}
 */
Date.prototype.subHours = function(hours) {
    return this.addHours(-hours);
}

/**
 * Adds the given day(s) to the date object.
 * @param {number} days
 * @return {Date}
 */
Date.prototype.addDays = function(days) {
    this.setDate(this.getDate() + days);

    return this;
}

/**
 * Subtracts the given day(s) from the date object.
 * @param {number} days
 * @return {Date}
 */
Date.prototype.subDays = function(days) {
    return this.addDays(-days);
}

/**
 * Alters the month number by the given amount.
 * @param {number} months
 * @return {Date}
 */
Date.prototype.addMonths = function(months) {
    this.setMonth(this.getMonth() + months);

    return this;
}

/**
 * Subtracts the month number by the given amount.
 * @param {number} months
 * @return {Date}
 */
Date.prototype.subMonths = function(months) {
    return this.addMonths(-months);
}

/**
 * Alters the year number by the given amount.
 * @param {number} years
 * @return {Date}
 */
Date.prototype.addYears = function(years) {
    this.setFullYear(this.getFullYear() + years);

    return this;
}

/**
 * Subtracts the year number by the given amount.
 * @param {number} years
 * @return {Date}
 */
Date.prototype.subYears = function(years) {
    return this.addYears(-years);
}

/**
 * Sets the time to be the start of the day.
 * @return {Date}
 */
Date.prototype.startOfDay = function() {
    this.setHours(0, 0, 0, 0);

    return this;
}

/**
 * Sets the time to be the last millisecond of the day.
 * @return {Date}
 */
Date.prototype.endOfDay = function() {
    this.setHours(23, 59, 59, 999);

    return this;
}

/**
 * Sets the day to be the start of the week (monday).
 * @return {Date}
 */
Date.prototype.startOfWeek = function() {
    this.setDate(this.getDate() - (this.getDay() === 0 ? 6 : this.getDay() - 1));

    return this;
}

/**
 * Sets the day to be the end of the week (sunday).
 * @return {Date}
 */
Date.prototype.endOfWeek = function() {
    return this.startOfWeek().addDays(6);
}

/**
 * Sets the date to the first day of the month.
 * @return {Date}
 */
Date.prototype.startOfMonth = function() {
    this.setDate(1);

    return this;
}

/**
 * Sets the date to the last day of the month.
 * @return {Date}
 */
Date.prototype.endOfMonth = function() {
    this.addMonths(1).setDate(0); // sets it to the last day of this month

    return this;
}

/**
 * Sets the day to be the first day of the year.
 * @return {Date}
 */
Date.prototype.startOfYear = function() {
    this.setMonth(0);
    this.setDate(1);

    return this;
}

/**
 * Sets the day to be the last day of the year.
 * @return {Date}
 */
Date.prototype.endOfYear = function() {
    this.setMonth(11);
    this.setDate(31);

    return this;
}

/**
 * Modifies the date, picking the lowest value which is either already set, or given to this method.
 * @param {Date} dates
 * @return {Date}
 */
Date.prototype.min = function(...dates) {
    this.setTime(Math.min(this.getTime(), ...dates.map(date => date.getTime())));
    return this;
}

/**
 * Modifies the date, picking the highest value which is either already set, or given to this method.
 * @param {Date} dates
 * @return {Date}
 */
Date.prototype.max = function(...dates) {
    this.setTime(Math.max(this.getTime(), ...dates.map(date => date.getTime())));
    return this;
}

/*
*
* Formatting
*
*/

// Getters

Date.prototype.formatPatterns = {
    Y: date => date.getFullYear(),                                  // Y, full year as integer
    m: date => (date.getMonth() + 1).toString().padStart(2, '0'),   // m, Month number with leading zeroes
    n: date => date.getMonth() + 1,                                 // n, Month number as integer
    j: date => date.getDate(),                                      // j, Day of month as integer
    d: date => date.getDate().toString().padStart(2, '0'),          // d, Day of month with leading zeroes
    g: date => date.getHours() % 12,                                // g, Hours as integer (12)
    h: date => (date.getHours() % 12).toString().padStart(2, '0'),  // h, Hours with leading zeroes (12)
    G: date => date.getHours(),                                     // G, Hours as integer (24)
    H: date => date.getHours().toString().padStart(2, '0'),         // H, Hours with leading zeroes (24)
    i: date => date.getMinutes().toString().padStart(2, '0'),       // i, Minutes with leading zeroes
    s: date => date.getSeconds().toString().padStart(2, '0'),       // s, Seconds with leading zeroes
    v: date => date.getMilliseconds(),                              // v, Milliseconds as integer
    a: date => date.getHours() < 12 ? 'am' : 'pm',                  // a, Lowercase Ante meridiem and Post meridiem
    A: date => date.getHours() < 12 ? 'AM' : 'PM',                  // A, Uppercase Ante meridiem and Post meridiem,
    W: date => date.getWeekNumber(),                                // W, the week number as integer
    z: date => date.getDayOfYear(),                                 // z, the day of the year as integer,
};

/**
 * Formats the date to the given pattern. Supports most patterns found in PHP. Letters can be escaped using the escape
 * character `\`, but make sure you escape the escape character too if building a string (`'\\'`).
 * @param {string} pattern
 * @return {string}
 */
Date.prototype.format = function(pattern) {

    let skipNext = false;
    return pattern
        .split('')
        .map(key => {
            if (skipNext) {
                // First handle the already activated escape so that the escape can also be escaped ;)
                skipNext = false;
                return key;
            }

            if (key === '\\') {
                skipNext = true;
                return '';
            }

            return this.formatPatterns[key] ? this.formatPatterns[key](this) : key;
        })
        .join('');
}

/*
*
* Properties
*
*/

/**
 * Returns the ISO week number.
 * @return {number}
 */
Date.prototype.getWeekNumber = function() {
    const d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate())),
        dayNum = d.getUTCDay() || 7;

    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));

    return Math.ceil((((d - yearStart) / 86400000) + 1)/7);
};

/**
 * Returns the amount of days the current month.
 * @return {number}
 */
Date.prototype.getDaysInMonth = function() {
    return new Date(this.getFullYear(), this.getMonth() + 1, 0).getDate();
}

/**
 * Returns whether the year is a leap year.
 * @return {boolean}
 */
Date.prototype.isLeapYear = function() {
    const year = this.getFullYear();

    if ((year & 3) !== 0) return false;
    return (year % 100) !== 0 || (year % 400) === 0;
}

/**
 * Returns the day of the year.
 * @return {number}
 */
Date.prototype.getDayOfYear = function() {
    // A list of all days per month of the preceding months.
    const dayCounts = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334],
        month = this.getMonth();
    let days = dayCounts[month] + this.getDate();
    // Edge case for march and beyond (0 = jan, 1 = feb, 2 = mar etc.)
    if (month > 1 && this.isLeapYear()) { ++days; }

    return days;
}

/**
 * Returns whether the day is in the midweek (mon, tue, wed, thu, fri).
 * @return {boolean}
 */
Date.prototype.isWeekday = function() {
    return [1, 2, 3, 4, 5].includes(this.getDay())
}

/**
 * Returns whether the day is in the weekend (sat & sun).
 * @return {boolean}
 */
Date.prototype.isWeekend = function() {
    return ! this.isWeekday();
}

/**
 * Returns whether the date is a Monday.
 * @return {boolean}
 */
Date.prototype.isMonday = function() {
    return this.getDay() === 1;
}

/**
 * Returns whether the date is a Tuesday.
 * @return {boolean}
 */
Date.prototype.isTuesday = function() {
    return this.getDay() === 2;
}

/**
 * Returns whether the date is a Wednesday.
 * @return {boolean}
 */
Date.prototype.isWednesday = function() {
    return this.getDay() === 3;
}

/**
 * Returns whether the date is a Thursday.
 * @return {boolean}
 */
Date.prototype.isThursday = function() {
    return this.getDay() === 4;
}

/**
 * Returns whether the date is a Friday.
 * @return {boolean}
 */
Date.prototype.isFriday = function() {
    return this.getDay() === 5;
}

/**
 * Returns whether the date is a Saturday.
 * @return {boolean}
 */
Date.prototype.isSaturday = function() {
    return this.getDay() === 6;
}

/**
 * Returns whether the date is a Sunday.
 * @return {boolean}
 */
Date.prototype.isSunday = function() {
    return this.getDay() === 0;
}

/**
 * Returns whether the date is the current day.
 * @return {boolean}
 */
Date.prototype.isToday = function() {
    return this.isSameDay(new Date());
}

/**
 * Returns whether the date is tomorrow.
 * @return {boolean}
 */
Date.prototype.isTomorrow = function() {
    return this.isSameDay(new Date().addDays(1));
}

/**
 * Returns whether the date is yesterday.
 * @return {boolean}
 */
Date.prototype.isYesterday = function() {
    return this.isSameDay(new Date().addDays(-1));
}

/**
 * Returns whether the date is this week.
 * @return {boolean}
 */
Date.prototype.isThisWeek = function() {
    return this.getWeekNumber() === new Date().getWeekNumber();
}

/**
 * Returns whether the date is next week.
 * @return {boolean}
 */
Date.prototype.isNextWeek = function() {
    return this.getWeekNumber() === new Date().addDays(7).getWeekNumber();
}

/**
 * Returns whether the date is last week.
 * @return {boolean}
 */
Date.prototype.isLastWeek = function() {
    return this.getWeekNumber() === new Date().addDays(-7).getWeekNumber();
}

/*
*
* Differences
*
*/

/**
 * Returns the difference between two dates in seconds.
 * @param {Date} date
 * @return {number} Difference in full seconds
 */
Date.prototype.diff = function(date) {
    return Math.floor((this.getTime() - date.getTime()) / 1000);
}

/**
 * Returns the difference between two dates in days.
 * @param {Date} date
 * @return {number} Difference in full days
 */
Date.prototype.diffInDays = function(date) {
    return Math.floor(this.diff(date) / 86400);
}

/**
 * Returns the difference between the two dates in months. This works differently from the seconds and days as the 31st of juli and the 1st of august count as '1'.
 * @param {Date} date
 * @return {number} Difference in whole real months.
 */
Date.prototype.diffInMonths = function(date) {
    // If they're equal, just shortcut to 0.
    if (this.getFullYear() === date.getFullYear() && this.getMonth() === date.getMonth()) {
        return 0;
    }

    return Math.abs(this.getFullYear() - date.getFullYear()) * 12 // Year difference
         + this > date ? (this.getMonth() - date.getMonth()) : (date.getMonth() - this.getMonth()); // Month difference
}

/*
*
* Comparison
*
*/

/**
 * Returns whether the given day is the same day as this one.
 * @param {Date} date
 * @return {boolean}
 */
Date.prototype.isSameDay = function(date) {
    return this.getDay() === date.getDay()
        && this.getMonth() === date.getMonth()
        && this.getFullYear() === date.getFullYear();
}

/*
*
* Periods
*
*/

/**
 *
 * @param {Date} date
 * @return {DatePeriod}
 */
Date.prototype.toPeriod = function(date) {
    return new DatePeriod(this, date);
}

const DateInterval = {
    second: 1,
    minute: 2,
    hour: 3,
    day: 4,
    month: 5,
    year: 6,
    week: 7,
};

/**
 * @property {Date} start
 * @property {Date} end
 * @property {number} interval see `DateInterval`
 * @property {number} stepSize
 */
class DatePeriod {

    /**
     * @param {Date} start
     * @param {Date} end
     */
    constructor(start, end) {
        this.start = start;
        this.end = end;
        this.setInterval(DateInterval.day);
    }

    /**
     * Sets the interval from one of the `DateInterval` values.
     * @param {Number} interval
     * @param {Number} [stepSize] Allows you to step ever X interval (e.g. every 12 hours)
     * @return {DatePeriod}
     */
    setInterval(interval, stepSize = 1) {
        this.interval = interval;
        this.stepSize = stepSize;

        return this;
    }

    /**
     * Returns if the given date can be found in this period.
     * @param {Date} date
     * @return {boolean}
     */
    includes(date) {
        return this.start <= date && this.end >= date;
    }

    /**
     * Returns whether this period ends before the given date (The date is later).
     * @param {Date} date
     * @return {boolean}
     */
    isBefore(date) {
        return this.end < date;
    }

    /**
     * Returns whether this period starts after the given date (the date is earlier).
     * @param {Date} date
     * @return {boolean}
     */
    isAfter(date) {
        return this.start > date;
    }

    /**
     * Returns whether the given period overlaps the current one in any way.
     * @param {DatePeriod} period
     * @return {boolean}
     */
    overlaps(period) {
        return this.includes(period.start)
            || this.includes(period.end)
            || this.start > period.start && this.end < period.end;
    }

    /**
     * Returns an array of each item in this period.
     * @return {Date[]}
     */
    toArray() {
        const a = [];
        this.forEach(date => a.push(date));
        return a;
    }

    /**
     * Runs a callback function on each item in this period.
     * @param {function(Date): void} callback
     */
    forEach(callback) {
        const d = this.start.clone();
        while (d <= this.end) {
            callback(d.clone());
            this._increment(d);
        }
    }

    /**
     * Runs a callback method on each item in this period, and returns the result.
     * @param {function(Date): T} callback
     * @return {T[]}
     */
    map(callback) {
        return this.toArray()
            .map(d => callback(d));
    }

    /**
     * Increments the
     * @param {Date} d
     * @return {Date}
     * @private
     */
    _increment(d) {
        switch (this.interval) {
            case DateInterval.second:
                return d.addSeconds(this.stepSize);
            case DateInterval.minute:
                return d.addMinutes(this.stepSize);
            case DateInterval.hour:
                return d.addHours(this.stepSize);
            case DateInterval.day:
                return d.addDays(this.stepSize);
            case DateInterval.month:
                return d.addMonths(this.stepSize);
            case DateInterval.year:
                return d.addYears(this.stepSize);
            case DateInterval.week:
                return d.addDays(7 * this.stepSize);
        }
    }

}
